import React from 'react';
import Slider from 'react-slick';

const AwardSlider = () => {
  const awardSettings = {
    autoplay: true,
    speed: 600,
    cssEase: 'cubic-bezier(0.65,0.05,0.36,1)',
    slidesToShow: 4,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    pauseOnFocus: false,
    arrows: false,
    responsive: [
      {
        mobileFirst: true,
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          mobileFirst: true,
        },
      },
    ],
  };
  return (
    <div className='awards awards--four'>
      <div className='container'>
        <Slider {...awardSettings} className='awards__slider'>
          <blockquote className='awards__item'>
            <div className='awards__itemInner'>
              <h2>
                ‘Best
                <br />
                Air Purifier’
              </h2>
              <cite>Popular Science</cite>
            </div>
          </blockquote>
          <blockquote className='awards__item'>
            <div className='awards__itemInner'>
              <h2>
                ‘A Truly Smart
                <br />
                Air Purifier’
              </h2>
              <cite>NYTimes/Wirecutter</cite>
            </div>
          </blockquote>
          <blockquote className='awards__item'>
            <div className='awards__itemInner'>
              <h2>
                ‘Best Smart
                <br />
                Air Purifier’
              </h2>
              <cite>Homes & Gardens</cite>
            </div>
          </blockquote>
          <blockquote className='awards__item'>
            <div className='awards__itemInner'>
              <h2>
                ‘Best Smart
                <br />
                Air Purifier’
              </h2>
              <cite>Gear Patrol</cite>
            </div>
          </blockquote>
        </Slider>
      </div>
    </div>
  );
};

export default AwardSlider;
